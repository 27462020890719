
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement(_Fragment, {}, [this.SearchVehicleWidget(function () {
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_breadcrumbs cm_SearchVehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cmTemplate_locked',
                'key': '74'
            }, _createElement('nav', { 'className': 'breadcrumbs' }, _createElement('a', { 'href': '/' }, 'Home'), _createElement('span', {}, '/'), '\n        Vehicle Search\n        ', _createElement('span', {}, '/'), '\n        ', this.vehicleString.replace(' ', ' / '), '\n      ')) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.CustomSearchBox(function () {
            return _createElement('div', { 'className': 'cm_CustomSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
                [this.searchInput(function () {
                        return _createElement('div', { 'className': 'cm_searchInput' }, 'Search...');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })],
                _createElement('span', {
                    'className': 'cm_search-box_clear-container',
                    'key': '1292'
                }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '419'
                }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
                _createElement('button', {
                    'type': 'button',
                    'title': 'Search',
                    'aria-label': 'search button',
                    'className': 'cm_search-box_submit cm_button cm_button__primary',
                    'data-cm-role': 'add-query',
                    'key': '1294'
                }, [_createElement('svg', {
                        'viewBox': '0 0 1024 1024',
                        'className': 'icon icon-search',
                        'id': 'icon-search',
                        'key': '7530'
                    }, _createElement('path', {
                        'className': 'path1',
                        'd': 'M966.070 981.101l-304.302-331.965c68.573-71.754 106.232-165.549 106.232-265.136 0-102.57-39.942-199-112.47-271.53s-168.96-112.47-271.53-112.47-199 39.942-271.53 112.47-112.47 168.96-112.47 271.53 39.942 199.002 112.47 271.53 168.96 112.47 271.53 112.47c88.362 0 172.152-29.667 240.043-84.248l304.285 331.947c5.050 5.507 11.954 8.301 18.878 8.301 6.179 0 12.378-2.226 17.293-6.728 10.421-9.555 11.126-25.749 1.571-36.171zM51.2 384c0-183.506 149.294-332.8 332.8-332.8s332.8 149.294 332.8 332.8-149.294 332.8-332.8 332.8-332.8-149.294-332.8-332.8z'
                    }))])
            ]), [this.dropdown(function () {
                    function repeatSections1(sections, sectionsIndex) {
                        return [sections(function () {
                                function repeatItems1(items, itemsIndex) {
                                    return [items(function () {
                                            function mergeProps_text(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function mergeProps_product(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function repeatI2_product(i, iIndex) {
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function onError1_facetValue(e) {
                                                e.target.classList.add('cm_hide');
                                            }
                                            function mergeProps_facetValue(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                'src': this.imageOrDefault(this.image),
                                                'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                                'onError': this.onImageError
                                            })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From: ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '), [this.review_count ? _createElement.apply(this, [
                                                    'div',
                                                    {
                                                        'className': 'cm_review-stars',
                                                        'key': '4680'
                                                    },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2_product.bind(this)),
                                                    _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                                                ]) : null])) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                                'className': 'image-block',
                                                'key': '35'
                                            }, _createElement('img', {
                                                'src': this.imageUrl,
                                                'alt': 'Image of ' + this.facetValue,
                                                'onError': onError1_facetValue.bind(this)
                                            })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                'className': 'hit-count',
                                                'key': '335'
                                            }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'section' }, this.sectionTitle ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '29'
                                }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_items' },
                                    _map(this.items, repeatItems1.bind(this))
                                ]));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_sections' },
                        _map(this.sections, repeatSections1.bind(this))
                    ]), this.browseAllButton ? [[this.browseAllButton(function () {
                                return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]] : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_header-title cm_SearchVehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cmTemplate_locked',
                'key': '75'
            }, 'Vehicle Search ', this.vehicleString) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_total-hits cm_total-hits__mobile cm_desktop-hide' }, _createElement('span', {}, this.totalHits, ' products')), _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [_createElement('div', {
            'className': 'cm_FacetPanel',
            'key': '98670'
        }, [this.VehicleSpecs(function () {
                function repeatSelects1(selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3520'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3722'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '39'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7541'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7544'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2842'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_VehicleSpecs' }, this.selects.length ? _createElement('div', {
                    'className': 'cm_vehicle-widget cm_vehicle-widget__specs',
                    'key': '29'
                }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facettitle' }, 'Vehicle specs'), _createElement('div', { 'className': 'facetbody cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement('div', {
                    'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                    'title': 'Reset',
                    'onClick': this.discardExtraFields
                }, '\n        Reset\n      ')))) : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FilterChips(function () {
                return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeDollarInchMm1_rangeChip() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, '\n    ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  ') : null, this.toPosInf ? _createElement(_Fragment, { 'key': '626' }, ' ', dollar, this.selection[0], inch, mm, ' and more ') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '732' }, ' ', dollar, this.selection[1], inch, mm, ' and less ') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'image',
                                                'viewBox': '0 0 22 22',
                                                'key': '8680'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', {
                            'className': 'facetbody',
                            'role': 'list'
                        }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_chips' },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarWheels(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3630'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3832'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8641'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8644'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'key': '2952'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Wheels';
                    return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar facetholder' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '66990'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarTires(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3630'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3832'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8641'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '8644'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'key': '2952'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Tires';
                    return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar facetholder' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '66970'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.Facets(function () {
                return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, !this.isExtraRange ? _createElement('span', { 'key': '217' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '278' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '394' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '497' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '609'
                                                }, this.hitCount) : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeDollarInchMm4() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '12490'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Min',
                                                                'className': 'cm_inputMin'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '334'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Max',
                                                                'className': 'cm_inputMax'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn cm_button cm_button__secondary',
                                                    'data-cm-role': 'add-facet'
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'key': '1630'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle up',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'key': '4630'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'role': 'list'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '962'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '1048'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1368'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '15371'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3368'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3694'
                                    }, [this.select(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry4(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3543'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3745'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '62'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled
                                            }, this.useNativeDropdown ? [
                                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                    'value': '',
                                                    'key': 'null-option'
                                                }, '\n          ', '', this.title, '\n        ') : null,
                                                this.loading ? _createElement('option', {
                                                    'key': 'loading-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '7771'
                                                    }, 'Popular Makes'),
                                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '7774'
                                                    }, 'All Makes'),
                                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                                ] : null,
                                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                                'key': '_current',
                                                'value': '_current'
                                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'key': '2865'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', '', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '9597'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '96811' }, '$', this.selection[0], ' to $', this.selection[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '96813'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '99491'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn cm_button cm_button__secondary',
                                                'data-cm-role': 'add-facet'
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '14403'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })])], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_dialogButton' }, [_createElement('svg', {
                                    'aria-hidden': 'true',
                                    'focusable': 'false',
                                    'role': 'presentation',
                                    'className': 'icon icon-filter',
                                    'viewBox': '0 0 64 64',
                                    'key': '290'
                                }, _createElement('path', { 'd': 'M48 42h10' }, '.'), _createElement('path', { 'd': 'M48 42a5 5 0 1 1-5-5 5 5 0 0 1 5 5z' }), _createElement('path', { 'd': 'M7 42h31' }, '.'), _createElement('path', { 'd': 'M16 22H6' }, '.'), _createElement('path', { 'd': 'M16 22a5 5 0 1 1 5 5 5 5 0 0 1-5-5z' }), _createElement('path', { 'd': 'M57 22H26' }, '.'))], ' Filter');
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' products')), _createElement('div', { 'className': 'cm_sort sort-by' }, this.sortSelect), [_createElement('ul', {
                    'className': 'cm_viewToggle no-bullets inline-list text-right',
                    'key': '14590'
                }, _createElement('li', {}, _createElement('button', {
                    'type': 'button',
                    'className': 'grid-view-btn' + (this.view === 'large' ? ' is-active' : ''),
                    'data-view': 'large',
                    'title': 'Large',
                    'onClick': this.setView('large')
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-view-large',
                    'viewBox': '0 0 35 35'
                }, _createElement('path', { 'd': 'M0 0h35v35H0z' })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Large'))), _createElement('li', {}, _createElement('button', {
                    'type': 'button',
                    'className': 'grid-view-btn' + (this.view === 'small' ? ' is-active' : ''),
                    'data-view': 'small',
                    'title': 'Small',
                    'onClick': this.setView('small')
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-view-small',
                    'viewBox': '0 0 35 35'
                }, _createElement('path', { 'd': 'M0 0h15v15H0zM20 0h15v15H20zM0 20h15v15H0zM20 20h15v15H20z' })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Small'))), _createElement('li', {}, _createElement('button', {
                    'type': 'button',
                    'className': 'grid-view-btn' + (this.view === 'list' ? ' is-active' : ''),
                    'data-view': 'list',
                    'title': 'List',
                    'onClick': this.setView('list')
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-view-list',
                    'viewBox': '0 0 35 35'
                }, _createElement('g', {
                    'id': 'Layer_2',
                    'data-name': 'Layer 2'
                }, _createElement('path', { 'd': 'M0 0h35v8H0zM0 13h35v8H0zM0 27h35v8H0z' }))), _createElement('span', { 'className': 'icon__fallback-text' }, 'List'))))]);
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function scopeColorArrImageVariantId1(colorOption, colorOptionIndex) {
                            var colorArr = colorOption.split('|');
                            var image = colorArr[1];
                            var variantId = colorArr[2].split('?variant=')[1];
                            return [_createElement('div', {
                                    'className': 'grid-product__color-image grid-product__color-image--' + variantId,
                                    'key': '13321'
                                })];
                        }
                        function repeatColorOption2(colorOption, colorOptionIndex, colorArr, image, variantId) {
                            return scopeColorArrImageVariantId1.apply(this, [
                                colorOption,
                                colorOptionIndex
                            ]);
                        }
                        function scopeColorArrColorImageUrlVariantId3(colorOption, colorOptionIndex) {
                            var colorArr = colorOption.split('|');
                            var color = colorArr[0];
                            var image = colorArr[1];
                            var url = colorArr[2];
                            var variantId = colorArr[2].split('?variant=')[1];
                            return [_createElement('span', {
                                    'className': 'color-swatch color-swatch--small color-swatch--' + color + ' color-swatch--with-image',
                                    'style': { backgroundImage: 'url(' + image + '?crop=center&height=400&width=400)' },
                                    'data-url': url,
                                    'onMouseEnter': globalThis.Convermax.changeImage(variantId, image),
                                    'onMouseLeave': globalThis.Convermax.setDefaltImage(variantId),
                                    'key': '19151'
                                }, _createElement('span', { 'className': 'visually-hidden' }, color))];
                        }
                        function repeatColorOption4(colorOption, colorOptionIndex, colorArr, color, image, url, variantId) {
                            return scopeColorArrColorImageUrlVariantId3.apply(this, [
                                colorOption,
                                colorOptionIndex
                            ]);
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function repeatI6(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        return _createElement('div', {
                            'className': 'grid-item grid-product',
                            'data-product-handle': this.handle,
                            'data-product-id': this.id
                        }, _createElement('div', { 'className': 'grid-item__content' }, _createElement('div', { 'className': 'grid-product__actions' }, !this.out_of_stock ? _createElement('button', {
                            'type': 'button',
                            'className': 'text-link quick-add-btn' + (this.variant_ids.length > 1 ? ' js-quick-add-form js-modal-open-quick-add' : ' js-quick-add-btn'),
                            'title': 'Add to cart',
                            'tabIndex': '-1',
                            'aria-expanded': 'false',
                            'data-id': this.variant_ids[0],
                            'key': '182'
                        }, _createElement('span', { 'className': 'btn btn--circle btn--icon' }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-plus',
                            'viewBox': '0 0 64 64'
                        }, _createElement('path', {
                            'className': 'cls-1',
                            'd': 'M32 9v46m23-23H9'
                        })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Add to cart'))) : null), _createElement('a', {
                            'href': this.url,
                            'className': 'grid-item__link'
                        }, _createElement('div', { 'className': 'grid-product__image-wrap' }, _createElement('div', { 'className': 'grid-product__tags' }), _createElement('div', { 'className': 'grid__image-ratio grid__image-ratio--square' }, _createElement('img', {
                            'className': 'lazy lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'loading': 'lazy'
                        })), this.product_colors && this.product_colors.length > 1 ? [_map(this.product_colors.slice(0, 4), repeatColorOption2.bind(this))] : null), _createElement('div', { 'className': 'grid-item__meta' }, _createElement('div', { 'className': 'grid-item__meta-main container-text' }, this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'grid-product__colors',
                                'key': '1795'
                            },
                            _map(this.product_colors.slice(0, 4), repeatColorOption4.bind(this)),
                            this.product_colors.length > 4 ? [_createElement('small', {
                                    'className': 'color-swatch__more',
                                    'key': '26661'
                                }, '+', this.product_colors.length - 4)] : null
                        ]) : null, _createElement('div', mergeProps({ 'className': 'grid-product__title' }, { dangerouslySetInnerHTML: { __html: this.title } }))), [this.review_count ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_review-stars',
                                    'key': '29820'
                                },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI6.bind(this)),
                                _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                            ]) : null], _createElement('div', { 'className': 'grid-item__meta-secondary' }, _createElement('div', { 'className': 'grid-product__price' }, _createElement('span', { 'className': 'grid-product__price--current' }, _createElement('span', {
                            'aria-hidden': 'true',
                            'className': this.price_varies ? 'grid-product__price--from' : ''
                        }, this.price_varies ? _createElement('span', { 'key': '3666' }, 'from ') : null, this.formatPrice(this.price), '\n              '), _createElement('span', { 'className': 'visually-hidden' }, this.formatPrice(this.price)))))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                {
                    'className': 'new-grid product-grid collection-grid cmRepeater_items',
                    'data-view': this.view
                },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('a', { 'className': 'cm_pagination__button prev btn btn--large btn--circle btn--icon cmTemplate_prev' }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-chevron-left',
                            'viewBox': '0 0 284.49 498.98'
                        }, _createElement('path', { 'd': 'M249.49 0a35 35 0 0 1 24.75 59.75L84.49 249.49l189.75 189.74a35.002 35.002 0 1 1-49.5 49.5L10.25 274.24a35 35 0 0 1 0-49.5L224.74 10.25A34.89 34.89 0 0 1 249.49 0z' }))) : this.template === 'next' ? _createElement('a', { 'className': 'cm_pagination__button next btn btn--large btn--circle btn--icon cmTemplate_next' }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-chevron-right',
                            'viewBox': '0 0 284.49 498.98'
                        }, _createElement('path', { 'd': 'M35 498.98a35 35 0 0 1-24.75-59.75l189.74-189.74L10.25 59.75a35.002 35.002 0 0 1 49.5-49.5l214.49 214.49a35 35 0 0 1 0 49.5L59.75 488.73A34.89 34.89 0 0 1 35 498.98z' }))) : this.template === 'delimiter' ? _createElement('span', { 'className': 'cm_pagination__button delimeter cmTemplate_delimiter' }, '...') : this.template === 'page' ? _createElement('span', { 'className': 'cm_pagination__button page' + (this.isActive ? ' current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement(_Fragment, { 'key': '115' }, this.page) : null, !this.isActive ? _createElement('a', {
                            'className': 'paging-links',
                            'key': '186'
                        }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'pagination cm_pagination' }, _createElement.apply(this, [
                'span',
                { 'className': 'cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:SearchVehicleWidget","cm:searchInput","cm:browseAllButton","cm:dropdown","cm:CustomSearchBox","cm:SearchVehicleWidget","cm:filterInput","cm:VehicleSpecs","cm:filterChips","cm:FilterChips","cm:filterInput","cm:FacetBarWheels","cm:filterInput","cm:FacetBarTires","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:SearchResult","cm:pagination"]